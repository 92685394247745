import InputMaskWrapper from './components/InputMaskWrapper';
import { Formik } from 'formik';
import * as Yup from 'yup';
import * as ApiManager from './services/api/ApiManager';
import { Images } from './assets';
import Swal from 'sweetalert2';
import { useState } from 'react';
import { CircularProgress } from '@mui/material';

function App() {
  const [loading, setLoading] = useState(false);
  return (
    <div style={{ height: '100vh' }} className='scroll-y-auto bg-white px-2'>
      <div className='flex items-center justify-center h-full'>
        <div className='w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700'>
          <Formik
            initialValues={{ amount: '' }}
            validationSchema={Yup.object().shape({
              amount: Yup.lazy((value) =>
                value === ''
                  ? Yup.string().required('Number of adults is required')
                  : Yup.number()
                      .min(1, 'Donation Amount must be 1 or grater')
                      .required('Donation Amount is required')
              ),
            })}
            onSubmit={async (values, actions) => {
              try {
                setLoading(true);
                const data = { amount: values.amount };
                const res = await ApiManager.donateAmount(data);
                console.log({ res });
                if (res.payment && res.payment?.status) {
                  Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: res?.payment?.message || 'Processed successfully',
                  });
                  actions.resetForm();
                } else {
                  Swal.fire({
                    icon: 'error',
                    title: 'Process Failed',
                    text: 'Unable to process. Please try again',
                  });
                }
                setLoading(false);
              } catch (ex) {
                setLoading(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
            }) => (
              <form
                onSubmit={handleSubmit}
                className='md:px-16 sm:px-8 px-4 py-8'
                autoComplete={false}
              >
                <div className='flex justify-center pb-6'>
                  <img
                    className='w-24 h-24 mb-3 rounded-full shadow-lg'
                    src={Images.Logo}
                    alt='Bonnie image'
                  />
                </div>
                <div className='flex flex-col justify-center pb-6'>
                  <label htmlFor='amount' className='pb-1'>
                    One Time Donation Amount
                  </label>
                  <InputMaskWrapper
                    mask='99999999999'
                    value={values.amount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id='amount'
                    required={false}
                    placeholder='Amount'
                    name='amount'
                  />
                  {touched.amount && errors.amount && (
                    <small className='text-red-500'>{errors.amount}</small>
                  )}
                </div>
                <div className='flex flex-col items-center'>
                  <div className='flex'>
                    <button
                      disabled={loading}
                      className='inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
                    >
                      {loading ? (
                        <div className='flex items-center gap-2'>
                          <span>Processing</span>
                          <CircularProgress
                            size={20}
                            sx={{ color: 'inherit' }}
                          />
                        </div>
                      ) : (
                        'Donate'
                      )}
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default App;
