import React from 'react';
import InputMask from 'react-input-mask';

const InputMaskWrapper = (props) => {
  return (
    <div className=''>
      {props.label && (
        <label htmlFor={props.id} className='form-label text-dark'>
          {props.label}{' '}
          {props.required ? <span className='text-danger'>*</span> : <></>}
        </label>
      )}

      <InputMask
        mask={props?.mask}
        value={props.value}
        disabled={false}
        maskChar=''
        onBlur={props.onBlur}
        onChange={props.onChange}
      >
        {(inputProps) => (
          <input
            className='shadow appearance-none border border-gray-200 rounded w-full py-2 px-3 text-gray-700 mb-1 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-500'
            id={props.id}
            min={props.min}
            max={props.max}
            {...inputProps}
            required={props.required}
            placeholder={props.placeholder}
            name={props.name}
          />
        )}
      </InputMask>
    </div>
  );
};

export default InputMaskWrapper;
