import { getData, postData } from './ApiCalls';

import { appSettings as s } from '../Settings';

export const donateAmount = async (data) => {
  try {
    const response = await postData(`${s.donation.donateAmount}`, data);
    return response;
  } catch (error) {
    return null;
  }
};
